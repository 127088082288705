<phar-entity-comments
  [users]="usersList()"
  [currentUser]="user()"
  [comments]="comments"
  [isViewOnly]="!(PermissionsEnum.AddCommentAssessments | hasPermissions)"
  [showCloseButton]="true"
  [isLoading]="isLoading()"
  [isEditActionVisible]="PermissionsEnum.EditCommentAssessments | hasPermissions"
  [isDeleteActionVisible]="PermissionsEnum.DeleteCommentAssessments | hasPermissions"
  [isResolveActionVisible]="PermissionsEnum.ResolveCommentAssessments | hasPermissions"
  (commentDeleted)="handleDeleteComment($event)"
  (commentResolved)="handleResolveComment($event)"
  (commentUpdated)="handleEditComment($event)"
  (commentCreated)="handleCreateComment($event)"
  (requestClose)="closeDialog()" />
